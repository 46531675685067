var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.6","spinner-variant":"primary"}},[_c('b-card',{attrs:{"title":"Shipment"}},[(!_vm.is_export)?_c('floating-title-badge',{attrs:{"message":_vm.ensLabelMessage,"is-success":_vm.ensCompliant}}):_vm._e(),_c('validation-observer',{ref:"shipmentValidation"},[_c('b-form',{ref:"shipmentForm"},[_c('b-row',[(_vm.canSelectType)?_c('b-col',{attrs:{"sm":"8","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"export-radio-group"}},[_c('b-form-radio-group',{attrs:{"id":"export-radio-group","name":"is_export","options":_vm.exportOptions},model:{value:(_vm.is_export),callback:function ($$v) {_vm.is_export=$$v},expression:"is_export"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Document number","label-for":"document-number"}},[_c('validation-provider',{attrs:{"name":"Document number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document-number","name":"document-number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.document_number),callback:function ($$v) {_vm.document_number=$$v},expression:"document_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Origin","label-for":"origin"}},[_c('validation-provider',{attrs:{"name":"Origin","rules":{ required: _vm.is_export }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"origin",attrs:{"id":"origin","options":_vm.airportsOptions,"name":"origin","placeholder":"Type to search...","state":errors.length > 0 ? false:null},on:{"search":_vm.fetchAirports},model:{value:(_vm.origin),callback:function ($$v) {_vm.origin=$$v},expression:"origin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Destination","label-for":"destination"}},[_c('validation-provider',{attrs:{"name":"Destination","rules":{ required: _vm.is_export }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"destination",attrs:{"id":"destination","options":_vm.airportsOptions,"name":"destination","placeholder":"Type to search...","state":errors.length > 0 ? false:null},on:{"search":_vm.fetchAirports},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(!_vm.is_export)?_c('b-col',{attrs:{"cols":"6","sm":"8","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Carrier","label-for":"carrier"}},[_c('validation-provider',{staticClass:"w-50",attrs:{"name":"Carrier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"carrier",attrs:{"id":"carrier","placeholder":_vm.carriersOptions.length > 0 ? 'Select carrier' : '',"disabled":_vm.carriersOptions.length === 0,"options":_vm.carriersOptions,"loading":_vm.carriersListLoading,"name":"carrier","state":errors.length > 0 ? false:null},model:{value:(_vm.carrier),callback:function ($$v) {_vm.carrier=$$v},expression:"carrier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1248896397)}),(_vm.carriersOptions.length === 0 && _vm.carriersListLoading !== true)?_c('div',{staticClass:"text-muted text-sm"},[(!_vm.companiesList.length || (_vm.companiesList.length && _vm.companyId && !_vm.carriersOptions.length))?_c('span',[_vm._v(" Add carriers "),_c('router-link',{attrs:{"to":{name:'carriers'}}},[_vm._v(" here ")])],1):_c('span',[_vm._v("Carriers list will be loaded once company is selected")])]):_vm._e()],1)],1):_vm._e(),(!_vm.is_export)?_c('b-col',{attrs:{"cols":"6","sm":"8","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Entry country","label-for":"entry_country"}},[_c('validation-provider',{attrs:{"name":"Entry country","rules":{ required: !_vm.is_export }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"entry_country","options":_vm.filteredCustoms,"name":"entry_country","state":errors.length > 0 ? false : null},model:{value:(_vm.entry_country),callback:function ($$v) {_vm.entry_country=$$v},expression:"entry_country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2467210829)})],1)],1):_vm._e(),(_vm.companyList.length > 1 && !_vm.is_export)?_c('b-col',{attrs:{"cols":"6","sm":"8","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company","clearable":false,"options":_vm.companiesList,"disabled":!!_vm.$props.shipmentId},on:{"input":_vm.getCarriersList},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2384474340)})],1)],1):_vm._e(),(_vm.canAddGrossWeight)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Document gross weight","label-for":"document-gross-weight"}},[_c('validation-provider',{attrs:{"name":"Document gross weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document-gross-weight","name":"document-gross-weight","disabled":_vm.is_pre_declaration_done,"state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.document_gross_weight),callback:function ($$v) {_vm.document_gross_weight=$$v},expression:"document_gross_weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1584758422)})],1)],1):_vm._e()],1),(_vm.canSelectDeclarationType)?_c('b-row',[_c('b-col',{attrs:{"sm":"8","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Declaration type","label-for":"declaration-type-radio-group"}},[_c('validation-provider',{attrs:{"name":"Declaration type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"declaration-type-radio-group","name":"declaration_type","options":_vm.declarationTypes,"state":errors.length > 0 ? false:null,"disabled":_vm.shipmentId},model:{value:(_vm.declaration_type),callback:function ($$v) {_vm.declaration_type=$$v},expression:"declaration_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3445665408)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.formSubmission,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"id":"submit-shipment","type":"button","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }