<template>
  <div class="floating-title-badge">
    <b-badge
      pill
      :variant="isSuccess ? 'success' : 'danger'"
    >
      <b-icon-check2-circle v-if="isSuccess"></b-icon-check2-circle>
      <b-icon-exclamation-circle v-else></b-icon-exclamation-circle>
      {{ message }}
    </b-badge>
  </div>
</template>
<script>
import {
  BBadge,
  BIconCheck2Circle,
  BIconExclamationCircle,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BIconCheck2Circle,
    BIconExclamationCircle,
  },
  props: {
    isSuccess: { type: Boolean, default: false },
    message: { type: String, default: '' },
  },
  data() {
    return {

    }
  },
}
</script>
<style lang="scss" scoped>
  .floating-title-badge {
    display: flex;
    justify-content: right;
    margin-top: -43px;
    margin-bottom: 20px;

    span {
      line-height: 16px;
    }
  }
</style>
